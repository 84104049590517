<template>
  <v-main>
    <v-overlay
      :value="requesting"
      z-index="300"
    >
      <v-progress-circular
        indeterminate
        size="64"
        z-index="301"
      />
    </v-overlay>
    <router-view />
    <dashboard-core-footer />
  </v-main>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'DashboardCoreView',
    components: {
      DashboardCoreFooter: () => import('./Footer'),
    },
    computed: {
      ...mapGetters(['requesting']),
    },
  }
</script>
